import React, { useEffect, useContext, useState } from 'react'
import AccountContext from '../context/AccountContext';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AccountCardGroup from '../components/accounts/AccountCardGroup';
import Checkbox from '../components/shared/Checkbox';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/shared/PrimaryButton';
import AccountTotalCashWidget from '../components/accounts/AccountTotalCashWidget';
import RolePermissionContainer from '../components/shared/RolePermissionContainer';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';
import { FaPlus } from 'react-icons/fa6';
import ParameterContext from '../context/ParameterContext';

function AccountsPage() {
  const navigate = useNavigate();

  const {filterAccount} = useContext(AccountContext)
  const {parameter, getParameter, updateParameter} = useContext(ParameterContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  const [localActiveOnly, setLocalActiveOnly] = useState(true)

  const [isLoading, setIsLoading] = useState(true)

  const [groupedInstitutionList, setGroupedInstitutionList] = useState([])
  const [groupedAccountList, setGroupedAccountList] = useState([])

  useEffect(() => {
    // TODO Listen to account deletion from supabase once it is supported
    if (isQuickDataLoaded && parameter) {
      let tempActiveOnly = getParameter("account")["active_only"]
      setLocalActiveOnly(tempActiveOnly)
      filter(tempActiveOnly)
      
      setIsLoading(false)      
    }    
  }, [isQuickDataLoaded, parameter])

  const filter = (localHideInactive) => {
    const tempList = filterAccount(localHideInactive)
    groupAccount(tempList)
  }

  const groupAccount = (localList) => {
    // Get unique institution first
    let localUniqueInstitutionList = []
    if (localList && localList.length > 0) {
      localUniqueInstitutionList = localList.reduce((total, item) => {
        return total.includes(item.institution) ? total : [...total, item.institution]
      }, [])      
    }
    let localGroupedAccountList = []
    localUniqueInstitutionList.forEach((i, index) => {
      let singleAccountList = localList.filter((t) => {
        return t.institution === i
      })
      localGroupedAccountList.push(singleAccountList)
    })

    setGroupedInstitutionList(localUniqueInstitutionList)
    setGroupedAccountList(localGroupedAccountList)
    
  }

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  return (
    <>
      <Header title='Accounts' />
      <Navbar currentPage='Accounts' />

      <AccountTotalCashWidget />

      <div className='flex items-center mb-8'>  
        <RolePermissionContainer permission={'create-account'}>
          <PrimaryButton onClick={e => navigate("/accounts/add")} className={'mr-4'}>
            <IconLabel
              left={<FaPlus />}
              right='Account'
            />
          </PrimaryButton>      
        </RolePermissionContainer>
        <Checkbox
          label='Active Account Only'
          name='activeOnly'
          id='activeOnly'
          checked={localActiveOnly}
          onChange={(e) => {
            let newActiveOnly = !localActiveOnly;
            updateParameter("account", "active_only", newActiveOnly)
          }}
          className="ml-auto md:ml-0"
        />
        
      </div>


      {groupedInstitutionList.map((item, index) => (
        <AccountCardGroup 
          key={index} 
          institution={item} 
          accountList={groupedAccountList[index]} />
      ))}
    </>
  )
}

export default AccountsPage
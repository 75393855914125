import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment'
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import PrimaryButton from '../components/shared/PrimaryButton';
import TransactionContext from '../context/TransactionContext';
import Radiobox from '../components/shared/Radiobox';
import Inputbox from '../components/shared/Inputbox';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js'
import TransactionCardGroup from '../components/transactions/TransactionCardGroup';
import TransactionTotalWidget from '../components/transactions/TransactionTotalWidget';
import { SmallerFont } from '../components/shared/Constants';
import UserContext from '../context/UserContext';
import RolePermissionContainer from '../components/shared/RolePermissionContainer';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';
import { FaPlus } from 'react-icons/fa';
import HighlightLabel from '../components/shared/HighlightLabel';
import ParameterContext from '../context/ParameterContext';

function TransactionsPage() {
  const navigate = useNavigate();

  const getDateRange = (profile) => {
    switch (profile) {
      case 'currentMonth': 
        return {
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        }
      case 'previousMonth': 
        return {
          start: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
          end: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD')
        }
      case 'currentYear': 
        return {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        }
      case 'previousYear': 
        return {
          start: moment().subtract(1, 'years').format('YYYY-01-01'),
          end: moment().startOf('year').subtract(1, 'days').format('YYYY-MM-DD')
        }
      default: 
        return {
          start: '',
          end: ''
        }
    }
  }

  const {transactionList, createViewModel, isFullTransactionDataLoaded} = useContext(TransactionContext)
  const {parameter, getParameter, updateParameter, updateParameterObject} = useContext(ParameterContext)
  const {colorTheme} = useContext(UserContext)
  const {isFullDataLoaded} = useContext(DataDependencyContext)
  
  const [isLoading, setIsLoading] = useState(true)

  const [filteredViewModelList, setFilteredViewModelList] = useState([])  

  const [groupedDateList, setGroupedDateList] = useState([])
  const [groupedTransactionList, setGroupedTransactionList] = useState([])

  const [searchedViewModelList, setSearchedViewModelList] = useState([])

  const [localsearchText, setLocalSearchText] = useState('')
  const [localStartDate, setLocalStartDate] = useState(getDateRange('currentMonth').start)
  const [localEndDate, setLocalEndDate] = useState(getDateRange('currentMonth').end)
  const [localDateProfile, setLocalDateProfile] = useState('currentMonth')
  const [isCustomFilterDate, setIsCustomFilterDate] = useState(false)

  useEffect(() => {
    if (isFullDataLoaded && parameter) {
      if (transactionList.length > 0) {
        // if date_profile is not specified
        // set to currentMonth if both start and end date are not specified
        // Otherwise, set to custom
        const dateProfile = getParameter("transaction")["date_profile"] || ((getParameter("transaction")["start_date"] || getParameter("transaction")["end_date"]) ? 'custom' : 'currentMonth')

        const startDate = getParameter("transaction")["start_date"]
        const endDate = getParameter("transaction")["end_date"]

        const tempPageParams = {
          start_date: startDate,
          end_date: endDate,
          date_profile: dateProfile
        }


        let tempTransactionList = [...transactionList]

        tempTransactionList = tempTransactionList.filter(t => 
          t.transaction_date >= startDate && t.transaction_date <= endDate)

        let viewModelList = tempTransactionList.map((t, index) => {
          const vm = createViewModel(t)
          return vm
        })

        setFilteredViewModelList(viewModelList)


        setIsCustomFilterDate(dateProfile === 'custom')
        setLocalStartDate(startDate)
        setLocalEndDate(endDate)
        setLocalDateProfile(dateProfile)
        
      }

      setIsLoading(false)      
    }    
  }, [isFullDataLoaded, transactionList, parameter])

  useEffect(() => {
    let tempVMList = []
    if (parameter) {
      let tempSearchText = getParameter("transaction")["search_text"]

      if (tempSearchText === '') {
        tempVMList = [...filteredViewModelList]
      } else {
        const fuseOptions = {
          // isCaseSensitive: false,
          // includeScore: false,
          // shouldSort: true,
          // includeMatches: false,
          // findAllMatches: false,
          // minMatchCharLength: 1,
          // location: 0,
          // threshold: 0.6,
          // distance: 100,
          // useExtendedSearch: false,
          // ignoreLocation: false,
          // ignoreFieldNorm: false,
          // fieldNormWeight: 1,
          includeMatches: true,
          minMatchCharLength: 2,
          threshold: 0.1,
          useExtendedSearch: true,
          keys: [
            "account_name",
            "tagNameList",
            "note",
            "transaction_date",
            "attribute"
          ]
        };
        
        const fuse = new Fuse(filteredViewModelList, fuseOptions);
  
        const searchResult = fuse.search(tempSearchText)
        tempVMList = searchResult.map(r => r.item)
      }
  
      setSearchedViewModelList(tempVMList)
      setLocalSearchText(tempSearchText)
    }
  }, [filteredViewModelList, parameter])

  useEffect(() => {
    if (!searchedViewModelList || searchedViewModelList.length === 0) {
      setGroupedDateList([])
      setGroupedTransactionList([])
    }
    if (searchedViewModelList && searchedViewModelList.length > 0) {
      // Get unique date first
      let localUniqueDateList = searchedViewModelList.reduce((total, item) => {
        return total.includes(item.transaction_date) ? total : [...total, item.transaction_date]
      }, [])
      localUniqueDateList.sort((a, b) => {
        if (a !== b) {
          return a > b ? -1 : 1
        }
        return 0
      })

      let localGroupedTransactionList = []
      localUniqueDateList.forEach((date, index) => {
        let dailyTransactionList = searchedViewModelList.filter((t) => {
          return t.transaction_date === date
        })
        dailyTransactionList.sort((a, b) => {
          if (a.type !== b.type) {
            return a.type === 'Income' ? -1 : 1
          }
          if (a.account_name !== b.account_name) {
            return a.account_name.localeCompare(b.account_name)
          }
          if (a.amount !== b.amount) {
            return a.amount > b.amount ? -1 : 1
          }
          return 0
        })
        localGroupedTransactionList.push(dailyTransactionList)
      })

      setGroupedDateList(localUniqueDateList)
      setGroupedTransactionList(localGroupedTransactionList)
    }
  }, [searchedViewModelList])

  const updateDateRange = (profile, customStart, customEnd) => {
    if (profile === 'custom' && customStart > customEnd) {
      toast.error('ERROR Start date cannot be later than End date')
      return
    }

    let tempStart = ''
    let tempEnd = ''
    if (profile === 'custom') {
      tempStart = customStart
      tempEnd = customEnd
    } else {
      const { start, end } = getDateRange(profile)
      tempStart = start
      tempEnd = end
    }    

    // Only set the search param, which will then set corresponding pageParams and local states
    const tempObject = {
      "start_date": tempStart,
      "end_date": tempEnd,
      "date_profile": profile
    }
    updateParameterObject("transaction", tempObject)
  }

  if (isLoading) {
    return (
      <>
        <Header title='Transactions' />
        <Navbar currentPage='Transactions' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Transactions' />
      <Navbar currentPage='Transactions' />

      <TransactionTotalWidget 
        transactionViewModelList={searchedViewModelList} 
        startDate={localStartDate}
        endDate={localEndDate}
      />

      <RolePermissionContainer permission={'create-transaction'}>
        <PrimaryButton onClick={e => navigate("/transactions/add")}>
          <IconLabel
            left={<FaPlus />}
            right='Transaction'
          />
        </PrimaryButton>      
      </RolePermissionContainer>
      <div className="mb-8"></div>

      <div className="mb-8">
        <Inputbox 
          name='searchWord'
          id='searchWord'
          placeholder='Search'
          value={localsearchText}
          onChange={(e) => {updateParameter("transaction", "search_text", e.target.value)}} 
          annotation="Advanced Syntax: (1) =exact, (2) 'include, (3) ^start, (4) .end$"
        />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div>
          <div className="font-bold">Filter by Date</div>

          {!isCustomFilterDate && (
            <div className={`mb-1 ${SmallerFont} ${colorTheme.secondary_text_color}`}>Selection: {localStartDate} - {localEndDate}</div>
          )}    
          <div>
            <Radiobox 
              label='Current Month'
              name='currentMonth'
              id='currentMonth'
              checked={localDateProfile === 'currentMonth'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Previous Month'
              name='previousMonth'
              id='previousMonth'
              checked={localDateProfile === 'previousMonth'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Current Year'
              name='currentYear'
              id='currentYear'
              checked={localDateProfile === 'currentYear'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>
          <div>
            <Radiobox 
              label='Previous Year'
              name='previousYear'
              id='previousYear'
              checked={localDateProfile === 'previousYear'} 
              onChange={(e) => updateDateRange(e.target.id)} 
            />
          </div>    
          <div>
            <Radiobox 
              label='Custom'
              name='custom'
              id='custom'
              checked={localDateProfile === 'custom'} 
              onChange={(e) => {
                updateParameter("transaction", "date_profile", "custom")
                setIsCustomFilterDate(true)}
              } 
            />
          </div>  
          {isCustomFilterDate && (
            <>
            <div className='grid grid-cols-2 gap-2 mt-2'>
              <div>
              <Inputbox 
                name='startDate'
                id='startDate'
                type='date'
                placeholder='Start Date'
                value={localStartDate}
                onChange={(e) => {updateDateRange("custom", e.target.value, localEndDate)}} 
              />
              </div>
              <div>
              <Inputbox 
                name='endDate'
                id='endDate'
                type='date'
                placeholder='End Date'
                value={localEndDate}
                onChange={(e) => {updateDateRange("custom", localStartDate, e.target.value)}} 
              />
              </div>
            </div>
            </>
          )}
        </div>
      </div>

      <div className="mb-8"></div>
      <div>Showing: {searchedViewModelList.length} transactions</div>

      <div className="mb-2"></div>
      <div className='flex items-center'>
        <div className="mr-2">Historical Data Status:</div>
        {isFullTransactionDataLoaded ? <HighlightLabel theme='green'>Loaded</HighlightLabel> : <HighlightLabel theme='red'>Loading...</HighlightLabel>}
      </div>

      <div className="mb-8"></div>
      {groupedDateList.map((item, index) => (
        <TransactionCardGroup key={index} date={item} transactionList={groupedTransactionList[index]} />
      ))}
    </>
    
  )
}

export default TransactionsPage
import React from 'react'
import { createContext, useState } from 'react'
import moment from 'moment'


const ParameterContext = createContext()

export const ParameterProvider = ({children}) => {
  const [parameter, setParameter] = useState({
    "account" : {
      "active_only": true
    },
    "investment": {
      "search_text": "\'active" // active order only by default
    },
    "transaction": {
      "search_text": "",
      "start_date": moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": moment().format('YYYY-MM-DD'),
      "date_profile": "currentMonth"
    },
    "actor": {
      "search_text": "",
      "table_view": "simple"
    }
  })

  const getParameter = (profile) => {
    return parameter[profile]
  }

  const updateParameter = (profile, key, value) => {
    // Check if the profile exists in the JSON object
    let temp = { ...parameter };
    if (temp.hasOwnProperty(profile)) {
        // Check if the key exists within the specific profile
        if (temp[profile].hasOwnProperty(key)) {
            // Update the value of the key
            temp[profile][key] = value;
            console.log(`Updated ${profile} -> ${key} to ${value}`);
            setParameter(temp)
        } else {
            console.log(`Key ${key} does not exist under ${profile}`);
        }
    } else {
        console.log(`Profile ${profile} does not exist`);
    }
  }

  const updateParameterObject = (profile, newObject) => {
    // Check if the profile exists in the JSON object
    let temp = { ...parameter };
    if (temp.hasOwnProperty(profile)) {
        // Check if the key exists within the specific profile
        temp[profile] = {...temp[profile], ...newObject}
        console.log(`Updated ${profile}`);
        setParameter(temp)
    } else {
        console.log(`Profile ${profile} does not exist`);
    }
  }

  return <ParameterContext.Provider 
    value={{
      parameter,
      getParameter,
      updateParameter,
      updateParameterObject
    }}>
      {children}
    </ParameterContext.Provider>
}

export default ParameterContext